<template>
  <div class="container-fluid container">
    <Breadcrumb />
    <!-- <h1>班級或班級管理</h1> -->
    <Heading content="班級或班級管理" />
    <hr />
    <div class="searchBar">
      <el-input
        ref="filterInput"
        v-model="keyword"
        placeholder="Please enter name"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          type="success"
          @click="searchByKeyWord"
        ></el-button>
      </el-input>
    </div>
    <div>
      <LessonRequestsForAdmin
        v-if="$store.getters['user/isRole']('super-admin')"
        @handleDelete="handleDelete"
        :allLesson="true"
      />
      <LessonRequestsForTeacher
        v-if="
          $store.getters['user/isRole']('teacher') &&
            !$store.getters['user/isRole']('super-admin')
        "
        @handleDelete="handleDelete"
        :allLesson="true"
      />
      <Heading heading="h4" :content="showMyClass" />
      <hr />
      <div>
        <el-button
          size="small"
          :type="isEnded ? 'default' : 'success'"
          @click="fetchOngoingLesson"
        >
          {{ $t("courses.ongoing") }}
        </el-button>
        <el-button
          size="small"
          :type="!isEnded ? 'default' : 'success'"
          @click="fetchInactiveLesson"
        >
          {{ $t("courses.inactive") }}
        </el-button>
      </div>
    </div>
    <el-table
      :data="privateLessonsList"
      :row-class-name="tableRowClassName"
      style="width: 100%; font-size: 14px;margin:20px 0"
    >
      <el-table-column label="#" prop="id" width="80" />
      <el-table-column width="40px">
        <template slot-scope="scope">
          <IsOnlineIcon :isOnline="scope.row.is_online" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.subject')" width="200px">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: getCourseDetailLink(
                scope.row.student_class_tickets[0],
                scope.row.student_class_tickets.length
              ),
              params: {
                id: scope.row.id,
                classId: scope.row.id
              }
            }"
          >
            {{ scope.row.subject ? scope.row.subject : scope.row.title }}
          </router-link>
        </template>
      </el-table-column>
      <!-- <el-table-column width="120px" :label="$t('courses.type')">
        <template slot-scope="scope">
          {{ getPrivateTypeName(scope.row.student_class_tickets[0]) }}
        </template>
      </el-table-column> -->
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div
            key="user"
            v-if="scope.row.active_session_class_teachers.length === 1"
          >
            <i class="fas fa-user" />
          </div>
          <div key="users" v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.teacher')">
        <template slot-scope="scope">
          <div
            :key="teacherItem.teacher_user.id"
            v-for="teacherItem in scope.row.active_session_class_teachers"
            class="userList"
          >
            <router-link
              :to="{
                name: 'filterAllStudentLessonsByUser',
                params: {
                  roleType: 'teacher',
                  userId: teacherItem.teacher_user.id
                }
              }"
            >
              {{
                `${userMethods.displayName(
                  teacherItem.teacher_user.first_name,
                  teacherItem.teacher_user.last_name
                )}`
              }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div key="user" v-if="scope.row.student_class_tickets.length === 1">
            <i class="fas fa-user" />
          </div>
          <div key="users" v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200px" :label="$t('column.student')">
        <template slot-scope="scope">
          <div
            :key="student.student_user_id"
            v-for="student in scope.row.student_class_tickets"
            class="userList student-list"
          >
            <div style="width:120px">
              <router-link
                :to="{
                  name: 'filterAllStudentLessonsByUser',
                  params: {
                    roleType: 'student',
                    userId: student.student_user_id
                  }
                }"
              >
                {{
                  `${userMethods.displayName(
                    student.first_name,
                    student.last_name
                  )}`
                }}
              </router-link>
            </div>
            <div style="flex: 1;">
              <el-tooltip
                class="item"
                effect="dark"
                :content="`點擊複製：${getContactTool(student).content}`"
                placement="top"
              >
                <el-button
                  type="success"
                  size="mini"
                  plain
                  @click="copyText(getContactTool(student).content)"
                  >{{ getContactTool(student).button }}</el-button
                >
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.progress')">
        <template slot-scope="scope">
          <span :class="scope.row.progress.toLowerCase()">
            {{ $t(`courses.progress.${scope.row.progress}`) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="100" :label="$t('column.firstLesson')">
        <template slot-scope="scope">
          <router-link
            v-if="
              isExistStudentClassTickets(scope.row.student_class_tickets) &&
                scope.row.student_class_tickets[0].first_lesson_log
            "
            :to="{
              name: 'privateLessonsTeachingLogDetail',
              params: {
                privateType: getPrivateType(scope.row.student_class_tickets[0]),
                courseId: scope.row.id,
                teachingLogId:
                  scope.row.student_class_tickets[0].first_lesson_log
                    .teaching_log_id
              }
            }"
          >
            {{
              instant.formatGoogleTime(
                scope.row.student_class_tickets[0].first_lesson_log.started_at
              )
            }}
          </router-link>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column width="100" :label="$t('column.lastLesson')">
        <template slot-scope="scope">
          <router-link
            v-if="
              isExistStudentClassTickets(scope.row.student_class_tickets) &&
                scope.row.student_class_tickets[0].last_lesson_log
            "
            :to="{
              name: 'privateLessonsTeachingLogDetail',
              params: {
                privateType: getPrivateType(scope.row.student_class_tickets[0]),
                courseId: scope.row.id,
                teachingLogId:
                  scope.row.student_class_tickets[0].last_lesson_log
                    .teaching_log_id
              }
            }"
          >
            {{
              instant.formatGoogleTime(
                scope.row.student_class_tickets[0].last_lesson_log.started_at
              )
            }}
          </router-link>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column width="120" :label="$t('column.nextLesson')">
        <template slot-scope="scope">
          <router-link
            v-if="
              isExistStudentClassTickets(scope.row.student_class_tickets) &&
                scope.row.student_class_tickets[0].next_lesson_log
            "
            :to="{
              name: 'privateLessonsTeachingLogDetail',
              params: {
                privateType: getPrivateType(scope.row.student_class_tickets[0]),
                courseId: scope.row.id,
                teachingLogId:
                  scope.row.student_class_tickets[0].next_lesson_log
                    .teaching_log_id
              }
            }"
          >
            {{
              instant.formatGoogleTime(
                scope.row.student_class_tickets[0].next_lesson_log.started_at
              )
            }}
          </router-link>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column v-if="isEnded" label="結束原因">
        <template slot-scope="scope">
          <EndReasonViewer :endReason="scope.row.end_reason || ''" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Details"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: getCourseDetailLink(
                  scope.row.student_class_tickets[0],
                  scope.row.student_class_tickets.length
                ),
                params: {
                  id: scope.row.id,
                  classId: scope.row.id
                }
              }"
            >
              <i class="el-icon-view" />
            </router-link>
          </el-tooltip>
          <template
            v-if="isEnded === 0 && ($store.getters['user/isRole']('super-admin') || isMyClass(scope.row.active_session_class_teachers))"
          >
            <el-tooltip
              class="item"
              content="Add a lesson log"
              placement="top"
              popper-class="tooltipColor"
            >
              <router-link
                v-if="
                  getPrivateType(scope.row.student_class_tickets[0]) != 'group'
                "
                :to="{
                  name: 'privateClassesCreateLog',
                  params: {
                    privateType: getPrivateType(
                      scope.row.student_class_tickets[0]
                    ),
                    id: scope.row.id
                  }
                }"
              >
                <i class="el-icon-plus" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'sessionClassesCreateLog',
                  params: { id: scope.row.id }
                }"
              >
                <i class="el-icon-plus" />
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="item"
              content="Add a progress report"
              placement="top"
            >
              <router-link
                v-if="
                  getPrivateType(scope.row.student_class_tickets[0]) != 'group'
                "
                :to="{
                  name: 'privateClassesCreateReport',
                  params: {
                    privateType: getPrivateType(
                      scope.row.student_class_tickets[0]
                    ),
                    classId: scope.row.id
                  }
                }"
              >
                <i class="el-icon-document" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'sessionClassesCreateReport',
                  params: { classId: scope.row.id }
                }"
              >
                <i class="el-icon-document" />
              </router-link>
            </el-tooltip>
            <el-tooltip
              key="inactive"
              class="item"
              content="End"
              placement="top"
              popper-class="tooltipColor"
              v-if="isEnded === 0"
            >
              <span @click="setEndLessonId(scope.row.id)">
                <i class="far fa-stop-circle action-icon" />
              </span>
            </el-tooltip>
            <el-tooltip
              key="active"
              class="item"
              content="Start"
              placement="top"
              popper-class="tooltipColor"
              v-else
            >
              <span @click="restartCustomLesson(scope.row.id)">
                <i class="far fa-play-circle action-icon" />
              </span>
            </el-tooltip>
          </template>

          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <router-link
              v-if="
                getPrivateType(scope.row.student_class_tickets[0]) != 'group'
              "
              :to="{
                name: 'privateClassesEdit',
                params: {
                  privateType: getPrivateType(
                    scope.row.student_class_tickets[0]
                  ),
                  class_id: scope.row.id
                }
              }"
            >
              <i class="el-icon-edit-outline" />
            </router-link>
            <router-link
              v-else
              :to="{ name: 'sessionClassesEdit', params: { id: scope.row.id } }"
            >
              <i class="el-icon-edit-outline" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Delete"
            placement="top"
            popper-class="tooltipColor"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <i
              class="el-icon-delete action-icon warning-icon"
              @click="
                handleDelete({ id: scope.row.id, callback: loadPrivateLessons })
              "
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="filterLessonsCondition.page"
        @current-change="changeLessonsPage"
        :total="privateLessonsListCount"
      >
      </el-pagination>
    </div>
    <EndLessonReasonDialog
      :visible="Boolean(handleEndClassId)"
      :classId="handleEndClassId"
      @onCancel="clearEndClassId"
      @onSubmitted="
        () => {
          clearEndClassId();
          loadPrivateLessons();
        }
      "
    />
  </div>
</template>

<script>
import moment from "moment";
import { user, instant } from "@ivy-way/material";
import EndReasonViewer from "@/components/privateLesson/EndReasonViewer";
import EndLessonReasonDialog from "@/views/private/EndLessonReasonDialog";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";
import enrollmentApi from "@/apis/enrollment";
import LessonRequestsForTeacher from "@/views/private/LessonRequestsForTeacher";
import LessonRequestsForAdmin from "@/views/private/LessonRequestsForAdmin";
import {
  EnumPrivateSessions,
  EnumContactTools,
  ContactColumnKeyMap
} from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.privateStudents")} - Ivy-Way Academy`
    };
  },
  components: {
    IsOnlineIcon,
    Breadcrumb,
    EndLessonReasonDialog,
    EndReasonViewer,
    LessonRequestsForTeacher,
    LessonRequestsForAdmin
  },
  mixins: [roleMixin, moneyMixin],
  computed: {
    showMyClass() {
      let title = this.$t("pageTitle.myClasses");
      if (this.$route.name === "filterAllStudentLessonsByUser") {
        if (this.$route.params.roleType === "student") {
          title = `${this.filterStudentFirstName} ${title}`;
        } else if (this.$route.params.roleType === "teacher") {
          title = `${this.filterTeacherFirstName} ${title}`;
        }
      }
      return title;
    },
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    filterStudentFirstName() {
      if (this.privateLessonsList.length > 0) {
        return (
          (this.privateLessonsList[0]?.student_class_tickets || []).find(
            ({ student_user_id }) =>
              student_user_id === Number(this.$route.params.userId)
          ).first_name + "'s "
        );
      } else {
        return "";
      }
    },
    filterTeacherFirstName() {
      if (this.privateLessonsList.length > 0) {
        return (
          (
            this.privateLessonsList[0]?.active_session_class_teachers || []
          ).find(
            ({ teacher_user_id }) =>
              teacher_user_id === Number(this.$route.params.userId)
          ).teacher_user.first_name + "'s "
        );
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      pageSize: 30,
      currentPage: 1,
      privateLessonsList: [],
      privateLessonsListCount: 0,
      isEnded: 0,
      keyword: "",
      handleEndClassId: null,
      filterLessonsCondition: {
        page: 1
      }
    };
  },
  async created() {
    if (this.$route.query.lessonsPage) {
      this.filterLessonsCondition = {
        page: Number(this.$route.query.lessonsPage)
      };
    }

    if (this.$route.query.isEnded) {
      this.isEnded = Number(this.$route.query.isEnded);
    }
    if (this.$route.query.search) {
      this.keyword = this.$route.query.search;
    }
    await this.loadPrivateLessons();
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") this.searchByKeyWord();
    });
  },
  methods: {
    isMyClass(teachers) {
      let isMy = false;
      teachers.forEach(teacher => {
        if(teacher.teacher_user.id === this.$store.getters["user/getProfile"].id) {
          isMy = true;
        };
      });
      return isMy;
    },
    moment() {
      return moment();
    },
    copyText(text) {
      // text是复制文本
      // 创建input元素
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", text);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
      this.$message({
        showClose: true,
        message: `已複製 ${text}`,
        type: "success"
      });
    },
    getCourseDetailLink(studentClassTicket, length) {
      if (this.getPrivateType(studentClassTicket) == "private-lessons") {
        if (length > 1) {
          return "classDetail";
        } else {
          return "classDetail";
        }
      } else if (this.getPrivateType(studentClassTicket) == "counseling") {
        return "counselingClassesDetail";
      } else {
        return "classDetail";
      }
    },
    getPrivateType(studentClassTicket) {
      const courseSessionId =
        studentClassTicket?.session_class.course_session_id;
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "private-lessons";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "counseling";
      }
      return "group";
    },
    getPrivateTypeName(studentClassTicket) {
      const courseSessionId =
        studentClassTicket?.session_class.course_session_id;
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "Private Lessons";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "Counseling";
      }
      return "Group";
    },
    getContactTool(studentClassTicket) {
      let contactTool =
        studentClassTicket?.session_class.contact_tool_preference;
      if (contactTool == null) {
        contactTool = 1;
      }
      return {
        button: `${EnumContactTools[contactTool]}`,
        content: `${studentClassTicket[ContactColumnKeyMap[contactTool]] ||
          "Missing"}`
      };
    },
    isExistStudentClassTickets(studentClassTickets) {
      return studentClassTickets.length > 0;
    },
    getLessonLogsCount(studentClassTickets) {
      return studentClassTickets[0]?.lesson_logs_cnt;
    },
    searchByKeyWord() {
      this.filterLessonsCondition.page = 1;
      this.$router.replace({
        name: "myClasses",
        query: {
          ...this.$route.query,
          search: this.keyword,
          lessonsPage: 1,
          lessonRequestsPage: 1
        }
      });
      // this.loadPrivateLessons();
    },
    async loadPrivateLessons() {
      let pairedQueryParams = {
        look_for_teacher: 0,
        is_ended: this.isEnded,
        keyword: this.keyword,
        page_size: this.pageSize,
        page: this.filterLessonsCondition.page,
        // course_session_type: "tutoring+counseling",
        ...this.filterCustomLessonByUserId()
      };
      let { session_classes: sessionClasses } = await courseApi.fetchMyClasses(
        pairedQueryParams
      );
      this.privateLessonsList = sessionClasses.data
        .filter(sessionClass => sessionClass.id)
        .map(sessionClass => ({
          ...sessionClass,
          student_class_tickets: sessionClass.student_class_tickets || [],
          active_session_class_teachers:
            sessionClass.active_session_class_teachers || []
        }));
      // if (this.isRoleTeacher() && !this.isRoleAdmin()) {
      //   this.privateLessonsList = this.privateLessonsList.map(sessionClass => ({
      //     ...sessionClass,
      //     active_session_class_teachers: [
      //       {
      //         teacher_user: {
      //           first_name: this.$store.getters["user/getProfile"].first_name,
      //           last_name: this.$store.getters["user/getProfile"].last_name,
      //           id: this.$store.getters["user/getProfile"].id
      //         }
      //       }
      //     ]
      //   }));
      // }
      this.privateLessonsListCount = sessionClasses.total;
    },

    async fetchOngoingLesson() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, isEnded: 0 }
      });
    },
    async fetchInactiveLesson() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, isEnded: 1 }
      });
    },
    filterCustomLessonByUserId() {
      if (this.$route.name === "filterAllStudentLessonsByUser") {
        const filterParams = {
          [this.$route.params.roleType === "teacher"
            ? "teacher_user_id"
            : "student_user_id"]: this.$route.params.userId
        };
        return filterParams;
      } else {
        return {};
      }
    },
    setEndLessonId(id) {
      this.handleEndClassId = id;
    },
    clearEndClassId() {
      this.handleEndClassId = null;
    },
    async restartCustomLesson(id) {
      try {
        await courseApi.restartSessionClass(id);
        await this.loadPrivateLessons();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async handleDelete({ id, callback }) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await courseApi.deleteSessionClass(id);
        callback();
        this.$message({
          showClose: true,
          message: this.$t("message.delete_success"),
          type: "success"
        });
      } catch (e) {
        // this.$message({
        //   showClose: true,
        //   message: this.$t("message.something_went_wrong"),
        //   type: "error"
        // });
      }
    },
    tableRowClassName({ row }) {
      if (
        (row.student_class_tickets || []).length > 1 ||
        (row.active_session_class_teachers || []).length > 1
      ) {
        return "multipleStudentsOrTeachers";
      }
      return "";
    },
    changeLessonsPage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          lessonsPage: page
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-input-group__append button.el-button {
  background: #42a16a;
  border-color: #42a16a;
  border-radius: 0 3px 3px 0;
  color: white;
}
.shortcutBtns > a {
  margin-right: 8px;
}

.container-fluid {
  padding: 20px;
  max-width: 95vw;
}
.item {
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

::v-deep .multipleStudentsOrTeachers {
  background: rgb(245, 247, 250);
}

.searchBar {
  /* display: grid;
  grid-template-columns: 1fr 100px;
  grid-column-gap: 8px; */
  width: 700px;
  max-width: 100%;
  margin-bottom: 12px;
}

.userList:not(:first-child) {
  border-top: 1px solid #ebeef5;
  padding-top: 8px;
  display: block;
}
.userList.student-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userList:not(:last-child) {
  padding-bottom: 8px;
}

.first_class_not_scheduled,
.meet_and_greet_not_scheduled {
  color: red;
}

.in_progress {
  color: #42a16a;
}
</style>
