<template>
  <div>
    <!-- <div>
      <h2>
        <span v-if="isFilterByUser">
          <span v-if="$route.params.roleType === 'student'">
            {{ filterStudentFirstName }}
          </span>
          <span v-else-if="$route.params.roleType === 'teacher'">
            {{ filterTeacherFirstName }}
          </span>
        </span>
        Lesson Requests
        <el-tooltip
          :enterable="false"
          content="新增課程班級"
          placement="top"
          popper-class="tooltipColor"
        >
          <router-link to="/create-class/how-to-billing">
            <i class="fas fa-plus" />
          </router-link>
        </el-tooltip>
      </h2>
    </div>
    <hr /> -->
    <Heading heading="h4" content="Lesson Requests">
      <router-link slot="button" to="/create-class/how-to-billing">
        <el-button type="primary" size="small">
          <i class="fas fa-plus" />
          新增課程班級
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <el-button
      size="small"
      :type="!look_for_teacher ? 'default' : 'success'"
      @click="look_for_teacher = 1"
    >
      {{ $t("courses.ongoing") }}
    </el-button>
    <el-button
      size="small"
      :type="look_for_teacher ? 'default' : 'success'"
      @click="look_for_teacher = 0"
    >
      {{ $t("courses.inactive") }}
    </el-button>
    <el-table
      :data="privateLessonRequestsList"
      :row-class-name="tableRowClassName"
      style="width: 100%; font-size: 14px;margin:20px 0"
    >
      <el-table-column label="#" prop="classId" width="80" />
      <el-table-column width="40px">
        <template slot-scope="scope">
          <IsOnlineIcon :isOnline="scope.row.is_online" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.subject')" width="200px">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: getClassDetailLink(scope.row.course_session_id),
              params: {
                id: scope.row.classId,
                class_id: scope.row.id ? scope.row.id : null
              }
            }"
          >
            {{ scope.row.subject }}
          </router-link>
        </template>
      </el-table-column>
      <!-- <el-table-column width="120px" label="Type">
        <template slot-scope="scope">
          {{ getPrivateTypeName(scope.row.student_class_tickets[0]) }}
        </template>
      </el-table-column> -->
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div v-if="scope.row.student_class_tickets.length === 1">
            <i class="fas fa-user" />
          </div>
          <div v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.student')">
        <template slot-scope="scope">
          <div
            :key="studentClassTicket.id"
            v-for="studentClassTicket in scope.row.student_class_tickets"
          >
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: studentClassTicket.student_user_id }
              }"
            >
              {{
                `${userMethods.displayName(
                  studentClassTicket.first_name,
                  studentClassTicket.last_name
                )}`
              }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.teachersInvited')" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.class_teacher_invitations.length < 4">
            <span
              v-for="(invitation, idx) in scope.row.class_teacher_invitations"
              :key="idx"
            >
              <router-link
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: invitation.teacher_user.id }
                }"
              >
                {{
                  `${userMethods.displayName(
                    invitation.teacher_user.first_name,
                    invitation.teacher_user.last_name
                  )}`
                }}<span
                  v-if="idx < scope.row.class_teacher_invitations.length - 1"
                  >,
                </span>
              </router-link>
            </span>
          </div>
          <div v-else>
            <span
              v-for="(invitation, index) in scope.row.class_teacher_invitations"
              :key="index"
            >
              <span v-if="index < 3">
                <router-link
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: invitation.teacher_user.id }
                  }"
                >
                  {{
                    `${userMethods.displayName(
                      invitation.teacher_user.first_name,
                      invitation.teacher_user.last_name
                    )}`
                  }}</router-link
                ><span v-if="index < 2">,</span>
              </span>
            </span>
            <span v-if="scope.row.class_teacher_invitations.length > 3">
              and {{ scope.row.class_teacher_invitations.length - 3 }} others
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.noReply')">
        <template slot-scope="scope">
          <div v-if="scope.row.no_reply_cnt > 0">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.no_reply_teachers.join(', ')"
              placement="top"
            >
              <el-link>{{ scope.row.no_reply_cnt }}</el-link>
            </el-tooltip>
          </div>
          <div v-else>0</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.accepted')">
        <template slot-scope="scope">
          <div v-if="scope.row.accept_cnt > 0">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.accept_teachers.join(', ')"
              placement="top"
            >
              <el-link>{{ scope.row.accept_cnt }}</el-link>
            </el-tooltip>
          </div>
          <div v-else>0</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.rejected')">
        <template slot-scope="scope">
          <div v-if="scope.row.reject_cnt > 0">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.reject_teachers.join(', ')"
              placement="top"
            >
              <el-link>{{ scope.row.reject_cnt }}</el-link>
            </el-tooltip>
          </div>
          <div v-else>0</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.action')" width="220">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Send invites to teachers"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'privateLessonInvitation',
                params: { id: scope.row.classId }
              }"
            >
              <i class="fas fa-user-plus action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Details"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: getClassDetailLink(scope.row.course_session.id),
                params: {
                  id: scope.row.classId,
                  class_id: scope.row.id
                }
              }"
            >
              <i class="el-icon-view action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
            v-if="isRoleAdmin()"
          >
            <router-link
              v-if="scope.row.course_session_type <= 2"
              :to="{
                name: 'privateClassesEdit',
                params: {
                  privateType: 'private-lessons',
                  class_id: scope.row.id
                }
              }"
            >
              <i class="el-icon-edit-outline action-icon" />
            </router-link>
            <router-link
              v-else-if="
                scope.row.course_session_type >= 3 &&
                  scope.row.course_session_type <= 6
              "
              :to="{
                name: 'privateClassesEdit',
                params: {
                  privateType: 'counseling',
                  class_id: scope.row.id
                }
              }"
            >
              <i class="el-icon-edit-outline action-icon" />
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'sessionClassesEdit',
                params: { id: scope.row.id }
              }"
            >
              <i class="el-icon-edit-outline action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Link student with a teacher"
            placement="top"
            popper-class="tooltipColor"
            v-if="isRoleAdmin()"
          >
            <router-link
              :to="{
                name: 'privateLessonLinkTeacher',
                params: {
                  privateType: isPrivateLesson
                    ? 'private-lessons'
                    : 'counseling',
                  class_id: scope.row.classId
                }
              }"
            >
              <i class="el-icon-connection action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Delete"
            placement="top"
            popper-class="tooltipColor"
            v-if="isRoleAdmin()"
          >
            <i
              class="el-icon-delete action-icon warning-icon"
              @click="() => handleDelete(scope.row.id)"
            />
          </el-tooltip>
          <template
            v-if="
              $store.getters['user/isRole']('super-admin') ||
                $store.getters['user/isRole']('teacher')
            "
          >
            <el-tooltip
              key="inactive"
              class="item"
              content="End"
              placement="top"
              popper-class="tooltipColor"
              v-if="look_for_teacher === 1"
            >
              <span @click="setRequest(scope.row.id, 0)">
                <i class="far fa-stop-circle action-icon" />
              </span>
            </el-tooltip>
            <el-tooltip
              key="active"
              class="item"
              content="Start"
              placement="top"
              popper-class="tooltipColor"
              v-else
            >
              <span @click="setRequest(scope.row.id, 1)">
                <i class="far fa-play-circle action-icon" />
              </span>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.responseStatus')">
        <template slot-scope="scope">
          <template v-if="isOwnerInvitation(scope.row)">
            <span v-show="scope.row.repliedStatus == 0" style="color:grey;">
              <i class="el-icon-question"></i>{{ $t("column.noReply") }}
            </span>
            <span
              v-show="scope.row.repliedStatus == 1"
              style="color:forestgreen;"
            >
              <i class="el-icon-success"></i>{{ $t("column.accepted") }}
            </span>
            <span
              v-show="scope.row.repliedStatus == 2"
              style="color:indianred;"
            >
              <i class="el-icon-error"></i>{{ $t("column.rejected") }}
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.editResponse')" width="300">
        <template slot-scope="scope">
          <div
            v-if="
              isEditReplyMode(scope.row.id) || scope.row.repliedStatus === 0
            "
          >
            <el-button
              v-if="isOwnerInvitation(scope.row)"
              :disabled="scope.row.repliedStatus === 1"
              type="primary"
              size="mini"
              @click="() => openTeacherAvailableDialog(scope.row)"
            >
              {{ $t("column.accept") }}
            </el-button>
            <el-button
              v-if="isOwnerInvitation(scope.row)"
              :disabled="scope.row.repliedStatus === 2"
              type="danger"
              size="mini"
              @click="
                () => {
                  replyInvitation(scope.row.id, scope.row.encryptedId, 2);
                }
              "
            >
              {{ $t("column.reject") }}
            </el-button>
            <span
              v-if="editReplyRows[scope.row.id]"
              @click="
                () => {
                  closeEditReplyBtns(scope.row.id);
                }
              "
            >
              <i class="el-icon-circle-close action-icon" />
            </span>
          </div>
          <div v-else>
            <i
              v-if="isOwnerInvitation(scope.row)"
              class="el-icon-edit-outline action-icon"
              @click="
                () => {
                  openEditReplyBtns(scope.row);
                }
              "
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="filterLessonRequestsCondition.page"
        @current-change="changeLessonRequestsPage"
        :total="privateLessonRequestsListCount"
      />
    </div>
    <br />
    <TeacherAvailableDialog
      v-if="acceptClass"
      :visible="teacherAvailableDialogVisible"
      :classId="acceptClass.session_class.id"
      @onSubmit="
        repliedNote =>
          replyInvitation(
            acceptClass.id,
            acceptClass.encryptedId,
            1,
            repliedNote
          )
      "
    />
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import moment from "moment";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import arrangeApi from "@/apis/arrange";
import enrollmentApi from "@/apis/enrollment";
import privateApi from "@/apis/private";
import courseApi from "@/apis/course";
import IsOnlineIcon from "@/components/icon/IsOnline";
import TeacherAvailableDialog from "@/views/private/TeacherAvailableDialog";
import { EnumPrivateSessions } from "@/enums";

export default {
  mixins: [moneyMixin, roleMixin],
  components: {
    IsOnlineIcon,
    TeacherAvailableDialog
  },
  props: {
    isPrivateLesson: {
      type: Boolean,
      default: false
    },
    allLesson: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    look_for_teacher() {
      this.loadPrivateLessonRequests();
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    isFilterByUser() {
      return (
        this.$route.name === "filterPrivateLessonsByUser" ||
        this.$route.name === "filterCounselingByUser"
      );
    },
    filterStudentFirstName() {
      if (this.privateLessonRequestsList.length > 0) {
        return (
          (this.privateLessonRequestsList[0]?.student_class_tickets || []).find(
            ({ student_user_id }) =>
              student_user_id === Number(this.$route.params.userId)
          ).first_name + "'s "
        );
      } else {
        return "";
      }
    },
    filterTeacherFirstName() {
      if (this.privateLessonRequestsList.length > 0) {
        return (
          (
            this.privateLessonRequestsList[0]?.session_class_teachers || []
          ).find(
            ({ teacher_user_id }) =>
              teacher_user_id === Number(this.$route.params.userId)
          ).teacher_user.first_name + "'s "
        );
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      pageSize: 30,
      currentPage: 1,
      privateLessonRequestsList: [],
      privateLessonRequestsListCount: 0,
      hasReplied: 0,
      teacherAvailableDialogVisible: false,
      acceptClass: null,
      filterLessonRequestsCondition: {
        page: 1
      },
      editReplyRows: {},
      keyword: null,
      look_for_teacher: 1
    };
  },
  created() {
    if (this.$route.query.lessonRequestsPage) {
      this.filterLessonRequestsCondition = {
        page: Number(this.$route.query.lessonRequestsPage)
      };
    }
    if (this.$route.query.search) {
      this.keyword = this.$route.query.search;
    }
    this.loadPrivateLessonRequests();
  },
  methods: {
    async setRequest(id, look_for_teacher) {
      // this.handleEndClassId = id;
      await courseApi.updateSessionClass(id, {
        look_for_teacher: look_for_teacher
      });
      this.loadPrivateLessonRequests();
    },
    getPrivateTypeName(studentClassTicket) {
      const courseSessionId =
        studentClassTicket?.session_class.course_session_id;
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "Private Lessons";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "Counseling";
      }
      return "Group";
    },
    isOwnerInvitation({ class_teacher_invitations }) {
      const userId = this.$store.getters["user/getProfile"].id;
      return class_teacher_invitations.some(
        invitationTeacher => invitationTeacher.teacher_user_id === userId
      );
    },
    openEditReplyBtns(row) {
      this.editReplyRows = {
        ...this.editReplyRows,
        [row.id]: true
      };
    },
    closeEditReplyBtns(id) {
      this.editReplyRows = {
        ...this.editReplyRows,
        [id]: false
      };
    },
    isEditReplyMode(id) {
      return this.editReplyRows[id];
    },
    setHasReplied() {
      this.hasReplied = this.hasReplied ? 0 : 1;
      this.loadPrivateLessonRequests();
    },
    async getLessonRequests(params) {
      if (this.$store.getters["user/isRole"]("super-admin")) {
        const userId = this.$store.getters["user/getProfile"].id;
        this.filterLessonRequestsCondition.page = params.page;
        let pairedQueryParams = {
          look_for_teacher: this.look_for_teacher,
          page_size: this.pageSize,
          ...params
        };
        const {
          session_classes: sessionClasses
        } = await courseApi.fetchAllLessonRequests(pairedQueryParams);
        return {
          ...sessionClasses,
          data: sessionClasses.data.map(sessionClass => {
            const teacherInvitation = sessionClass.class_teacher_invitations.find(
              teacherInvitation => teacherInvitation.teacher_user_id === userId
            );

            return {
              ...sessionClass,
              classId: sessionClass.id,
              teacherInvitationId: teacherInvitation
                ? teacherInvitation.id
                : "",
              repliedStatus: teacherInvitation
                ? teacherInvitation.replied_status
                : ""
            };
          })
        };
      } else if (this.$store.getters["user/isRole"]("teacher")) {
        let pairedQueryParams = {
          has_replied: this.hasReplied,
          page: params.page
        };
        const {
          class_teacher_invitations: { data: teacherInvitations, total }
        } = await privateApi.fetchLessonRequestsByTeacher(pairedQueryParams);
        return {
          data: teacherInvitations
            .filter(({ session_class }) => session_class)
            .map(classTeacherInvitation => ({
              ...classTeacherInvitation,
              classId: classTeacherInvitation.class_id,
              student_class_tickets: classTeacherInvitation.session_class.student_class_tickets.map(
                studentClassTicket => ({
                  ...studentClassTicket,
                  first_name: studentClassTicket.student.first_name,
                  last_name: studentClassTicket.student.last_name
                })
              ),
              encryptedId: classTeacherInvitation.encrypted_id,
              subject: classTeacherInvitation.session_class.subject,
              teacherInvitationId: classTeacherInvitation.id,
              repliedStatus: classTeacherInvitation.replied_status,
              requestDate: moment(classTeacherInvitation.created_at).format(
                "YYYY-MM-DD"
              ),
              salary: classTeacherInvitation.session_class.is_online
                ? Number(classTeacherInvitation.online_salary)
                : Number(classTeacherInvitation.salary),
              salaryCurrency: classTeacherInvitation.session_class.is_online
                ? classTeacherInvitation.online_salary_currency
                : classTeacherInvitation.currency
            })),
          total
        };
      }
    },
    async loadPrivateLessonRequests() {
      const page = this.filterLessonRequestsCondition.page;
      const params = {
        page,
        course_session_type: this.isPrivateLesson ? "tutoring" : "counseling",
        keyword: this.keyword,
        ...this.filterCustomLessonByUserId()
      };
      if (this.allLesson) {
        params.course_session_type = null;
      }
      const studentClassTickets = await this.getLessonRequests(params);

      if (studentClassTickets) {
        this.privateLessonRequestsList = studentClassTickets.data;
        this.privateLessonRequestsListCount = studentClassTickets.total;
      }
    },
    filterCustomLessonByUserId() {
      if (this.isFilterByUser) {
        const filterParams = {
          [this.$route.params.roleType === "teacher"
            ? "teacher_user_id"
            : "student_user_id"]: this.$route.params.userId
        };
        return filterParams;
      } else {
        return {};
      }
    },
    changeLessonRequestsPage(page) {
      this.$router.push({
        name: "privateLessons",
        query: {
          lessonRequestsPage: page
        }
      });
    },
    async handleDelete(id) {
      await this.$emit("handleDelete", {
        id,
        callback: this.loadPrivateLessonRequests
      });
    },
    async replyInvitation(id, teacherInvitationId, replyStatus, repliedNote) {
      this.closeEditReplyBtns(id);
      let param = {
        class_teacher_invitation_id: teacherInvitationId,
        replied_status: replyStatus
      };

      if (replyStatus === 1) {
        param.replied_note = repliedNote;
      }

      await arrangeApi.replyTeacherInvitation(param);
      this.loadPrivateLessonRequests();
      this.closeTeacherAvailableDialog();
    },
    tableRowClassName({ row }) {
      if (row.student_class_tickets.length > 1) {
        return "multipleStudents";
      }
      return "";
    },
    openTeacherAvailableDialog(acceptClass) {
      this.acceptClass = acceptClass;
      this.teacherAvailableDialogVisible = true;
    },
    closeTeacherAvailableDialog() {
      this.acceptClass = null;
      this.teacherAvailableDialogVisible = false;
    },
    getClassDetailLink(courseSessionId) {
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "privateClassesDetail";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "privateClassesDetail";
      } else {
        return "classDetail";
      }
    },
    getClassEditLink(courseSessionId) {
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "privateClassesEdit";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "privateClassesEdit";
      } else {
        return "sessionClassesEdit";
      }
    }
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 20px;
  max-width: 95vw;
}

.item {
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

::v-deep .multipleStudents {
  background: rgb(245, 247, 250);
}
</style>
