<template>
  <div>
    <Heading heading="h4" content="Lesson Requests"> </Heading>
    <hr />
    <div>
      <el-button
        size="small"
        :type="hasReplied ? 'default' : 'success'"
        @click="setHasReplied"
      >
        未回覆
      </el-button>
      <el-button
        size="small"
        :type="!hasReplied ? 'default' : 'success'"
        @click="setHasReplied"
      >
        已回覆
      </el-button>
    </div>
    <el-table
      :data="privateLessonRequestsList"
      :row-class-name="tableRowClassName"
      style="width: 100%; font-size: 14px;margin:20px 0"
    >
      <el-table-column label="#" prop="classId" width="80" />
      <el-table-column width="40px">
        <template slot-scope="scope">
          <IsOnlineIcon :isOnline="scope.row.session_class.is_online" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.subject')" width="200px">
        <template slot-scope="scope">
          <!-- <router-link
            :to="{
              name: getClassDetailLink(scope.row.session_class.course_session_id),
              params: {
                id: scope.row.classId,
                class_id: scope.row.classId ? scope.row.classId : null
              }
            }"
          >
            {{ scope.row.subject }}
          </router-link> -->
          {{ scope.row.subject }}
        </template>
      </el-table-column>
      <el-table-column width="120px" label="Type">
        <template slot-scope="scope">
          {{ getPrivateTypeName(scope.row.student_class_tickets[0]) }}
        </template>
      </el-table-column>
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div v-if="scope.row.student_class_tickets.length === 1">
            <i class="fas fa-user" />
          </div>
          <div v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.student')">
        <template slot-scope="scope">
          <div
            :key="studentClassTicket.id"
            v-for="studentClassTicket in scope.row.student_class_tickets"
          >
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: studentClassTicket.student_user_id }
              }"
            >
              {{
                `${userMethods.displayName(
                  studentClassTicket.first_name,
                  studentClassTicket.last_name
                )}`
              }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Salary">
        <template slot-scope="scope">
          <span
            >{{
              addCurrencySymbol(scope.row.salary, scope.row.salaryCurrency)
            }}
            /hr</span
          >
        </template>
      </el-table-column>
      <el-table-column label="Request Date">
        <template slot-scope="scope">
          <span>{{ scope.row.requestDate }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.responseStatus')">
        <template slot-scope="scope">
          <template>
            <span v-show="scope.row.repliedStatus == 0" style="color:grey;">
              <i class="el-icon-question"></i>{{ $t("column.noReply") }}
            </span>
            <span
              v-show="scope.row.repliedStatus == 1"
              style="color:forestgreen;"
            >
              <i class="el-icon-success"></i>{{ $t("column.accepted") }}
            </span>
            <span
              v-show="scope.row.repliedStatus == 2"
              style="color:indianred;"
            >
              <i class="el-icon-error"></i>{{ $t("column.rejected") }}
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.editResponse')" width="300">
        <template slot-scope="scope">
          <div
            v-if="
              isEditReplyMode(scope.row.id) || scope.row.repliedStatus === 0
            "
          >
            <el-button
              :disabled="scope.row.repliedStatus === 1"
              type="primary"
              size="mini"
              @click="() => openTeacherAvailableDialog(scope.row)"
            >
              {{ $t("column.accept") }}
            </el-button>
            <el-button
              :disabled="scope.row.repliedStatus === 2"
              type="danger"
              size="mini"
              @click="
                () => {
                  replyInvitation(scope.row.id, scope.row.encryptedId, 2);
                }
              "
            >
              {{ $t("column.reject") }}
            </el-button>
            <span
              v-if="editReplyRows[scope.row.id]"
              @click="
                () => {
                  closeEditReplyBtns(scope.row.id);
                }
              "
            >
              <i class="el-icon-circle-close action-icon" />
            </span>
          </div>
          <div v-else>
            <i
              class="el-icon-edit-outline action-icon"
              @click="
                () => {
                  openEditReplyBtns(scope.row);
                }
              "
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="filterLessonRequestsCondition.page"
        @current-change="changeLessonRequestsPage"
        :total="privateLessonRequestsListCount"
      />
    </div>
    <br />
    <TeacherAvailableDialog
      v-if="acceptClass"
      :visible="teacherAvailableDialogVisible"
      :classId="acceptClass.session_class.id"
      @onSubmit="
        repliedNote =>
          replyInvitation(
            acceptClass.id,
            acceptClass.encryptedId,
            1,
            repliedNote
          )
      "
    />
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import moment from "moment";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import arrangeApi from "@/apis/arrange";
import privateApi from "@/apis/private";
import IsOnlineIcon from "@/components/icon/IsOnline";
import TeacherAvailableDialog from "@/views/private/TeacherAvailableDialog";
import { EnumPrivateSessions } from "@/enums";

export default {
  mixins: [moneyMixin, roleMixin],
  components: {
    IsOnlineIcon,
    TeacherAvailableDialog
  },
  props: {
    allLesson: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userMethods() {
      return user;
    }
  },
  data() {
    return {
      pageSize: 30,
      currentPage: 1,
      privateLessonRequestsList: [],
      privateLessonRequestsListCount: 0,
      hasReplied: 0,
      teacherAvailableDialogVisible: false,
      acceptClass: null,
      filterLessonRequestsCondition: {
        page: 1
      },
      editReplyRows: {},
      keyword: null
    };
  },
  created() {
    if (this.$route.query.lessonRequestsPage) {
      this.filterLessonRequestsCondition = {
        page: Number(this.$route.query.lessonRequestsPage)
      };
    }
    if (this.$route.query.search) {
      this.keyword = this.$route.query.search;
    }
    if (this.$route.query.hasReplied) {
      this.hasReplied = Number(this.$route.query.hasReplied);
    }
    this.loadPrivateLessonRequests();
  },
  methods: {
    getClassDetailLink(courseSessionId) {
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "privateClassesDetail";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "counselingClassesDetail";
      } else {
        return "classDetail";
      }
    },
    getPrivateTypeName(studentClassTicket) {
      const courseSessionId =
        studentClassTicket?.session_class.course_session_id;
      if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
        return "Private Lessons";
      } else if (EnumPrivateSessions.counselingIds.includes(courseSessionId)) {
        return "Counseling";
      }
      return "Group";
    },
    openEditReplyBtns(row) {
      this.editReplyRows = {
        ...this.editReplyRows,
        [row.id]: true
      };
    },
    closeEditReplyBtns(id) {
      this.editReplyRows = {
        ...this.editReplyRows,
        [id]: false
      };
    },
    isEditReplyMode(id) {
      return this.editReplyRows[id];
    },
    setHasReplied() {
      this.hasReplied = this.hasReplied ? 0 : 1;
      this.$router.replace({
        name: "myClasses",
        query: {
          ...this.$route.query,
          hasReplied: this.hasReplied
        }
      });
      // this.loadPrivateLessonRequests();
    },
    async getLessonRequests(params) {
      let pairedQueryParams = {
        has_replied: this.hasReplied,
        keyword: this.keyword,
        page: params.page
      };
      console.log(pairedQueryParams);
      const {
        class_teacher_invitations: { data: teacherInvitations, total }
      } = await privateApi.fetchLessonRequestsByTeacher(pairedQueryParams);
      return {
        data: teacherInvitations
          .filter(({ session_class }) => session_class)
          .map(classTeacherInvitation => ({
            ...classTeacherInvitation,
            classId: classTeacherInvitation.class_id,
            student_class_tickets: classTeacherInvitation.session_class.student_class_tickets.map(
              studentClassTicket => ({
                ...studentClassTicket,
                first_name: studentClassTicket.student.first_name,
                last_name: studentClassTicket.student.last_name
              })
            ),
            encryptedId: classTeacherInvitation.encrypted_id,
            subject: classTeacherInvitation.session_class.subject,
            teacherInvitationId: classTeacherInvitation.id,
            repliedStatus: classTeacherInvitation.replied_status,
            requestDate: moment(classTeacherInvitation.created_at).format(
              "YYYY-MM-DD"
            ),
            salary: classTeacherInvitation.session_class.is_online
              ? Number(classTeacherInvitation.online_salary)
              : Number(classTeacherInvitation.salary),
            salaryCurrency: classTeacherInvitation.session_class.is_online
              ? classTeacherInvitation.online_salary_currency
              : classTeacherInvitation.currency
          })),
        total
      };
    },
    async loadPrivateLessonRequests() {
      const page = this.filterLessonRequestsCondition.page;
      const params = {
        page,
        course_session_type: null
      };
      const studentClassTickets = await this.getLessonRequests(params);

      if (studentClassTickets) {
        this.privateLessonRequestsList = studentClassTickets.data;
        this.privateLessonRequestsListCount = studentClassTickets.total;
      }
    },
    changeLessonRequestsPage(page) {
      this.$router.push({
        name: "privateLessons",
        query: {
          lessonRequestsPage: page
        }
      });
    },
    async replyInvitation(id, teacherInvitationId, replyStatus, repliedNote) {
      this.closeEditReplyBtns(id);
      let param = {
        class_teacher_invitation_id: teacherInvitationId,
        replied_status: replyStatus
      };

      if (replyStatus === 1) {
        param.replied_note = repliedNote;
      }

      await arrangeApi.replyTeacherInvitation(param);
      this.loadPrivateLessonRequests();
      this.closeTeacherAvailableDialog();
    },
    tableRowClassName({ row }) {
      if (row.student_class_tickets.length > 1) {
        return "multipleStudents";
      }
      return "";
    },
    openTeacherAvailableDialog(acceptClass) {
      this.acceptClass = acceptClass;
      this.teacherAvailableDialogVisible = true;
    },
    closeTeacherAvailableDialog() {
      this.acceptClass = null;
      this.teacherAvailableDialogVisible = false;
    }
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 20px;
  max-width: 95vw;
}

.item {
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

::v-deep .multipleStudents {
  background: rgb(245, 247, 250);
}
</style>
