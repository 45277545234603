var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid container"},[_c('Breadcrumb'),_c('Heading',{attrs:{"content":"班級或班級管理"}}),_c('hr'),_c('div',{staticClass:"searchBar"},[_c('el-input',{ref:"filterInput",attrs:{"placeholder":"Please enter name"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search","type":"success"},on:{"click":_vm.searchByKeyWord},slot:"append"})],1)],1),_c('div',[(_vm.$store.getters['user/isRole']('super-admin'))?_c('LessonRequestsForAdmin',{attrs:{"allLesson":true},on:{"handleDelete":_vm.handleDelete}}):_vm._e(),(
        _vm.$store.getters['user/isRole']('teacher') &&
          !_vm.$store.getters['user/isRole']('super-admin')
      )?_c('LessonRequestsForTeacher',{attrs:{"allLesson":true},on:{"handleDelete":_vm.handleDelete}}):_vm._e(),_c('Heading',{attrs:{"heading":"h4","content":_vm.showMyClass}}),_c('hr'),_c('div',[_c('el-button',{attrs:{"size":"small","type":_vm.isEnded ? 'default' : 'success'},on:{"click":_vm.fetchOngoingLesson}},[_vm._v(" "+_vm._s(_vm.$t("courses.ongoing"))+" ")]),_c('el-button',{attrs:{"size":"small","type":!_vm.isEnded ? 'default' : 'success'},on:{"click":_vm.fetchInactiveLesson}},[_vm._v(" "+_vm._s(_vm.$t("courses.inactive"))+" ")])],1)],1),_c('el-table',{staticStyle:{"width":"100%","font-size":"14px","margin":"20px 0"},attrs:{"data":_vm.privateLessonsList,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":"#","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('IsOnlineIcon',{attrs:{"isOnline":scope.row.is_online}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.subject'),"width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: _vm.getCourseDetailLink(
              scope.row.student_class_tickets[0],
              scope.row.student_class_tickets.length
            ),
            params: {
              id: scope.row.id,
              classId: scope.row.id
            }
          }}},[_vm._v(" "+_vm._s(scope.row.subject ? scope.row.subject : scope.row.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.active_session_class_teachers.length === 1)?_c('div',{key:"user"},[_c('i',{staticClass:"fas fa-user"})]):_c('div',{key:"users"},[_c('i',{staticClass:"fas fa-users"})])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.teacher')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.active_session_class_teachers),function(teacherItem){return _c('div',{key:teacherItem.teacher_user.id,staticClass:"userList"},[_c('router-link',{attrs:{"to":{
              name: 'filterAllStudentLessonsByUser',
              params: {
                roleType: 'teacher',
                userId: teacherItem.teacher_user.id
              }
            }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( teacherItem.teacher_user.first_name, teacherItem.teacher_user.last_name )}`)+" ")])],1)})}}])}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.student_class_tickets.length === 1)?_c('div',{key:"user"},[_c('i',{staticClass:"fas fa-user"})]):_c('div',{key:"users"},[_c('i',{staticClass:"fas fa-users"})])]}}])}),_c('el-table-column',{attrs:{"width":"200px","label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.student_class_tickets),function(student){return _c('div',{key:student.student_user_id,staticClass:"userList student-list"},[_c('div',{staticStyle:{"width":"120px"}},[_c('router-link',{attrs:{"to":{
                name: 'filterAllStudentLessonsByUser',
                params: {
                  roleType: 'student',
                  userId: student.student_user_id
                }
              }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( student.first_name, student.last_name )}`)+" ")])],1),_c('div',{staticStyle:{"flex":"1"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":`點擊複製：${_vm.getContactTool(student).content}`,"placement":"top"}},[_c('el-button',{attrs:{"type":"success","size":"mini","plain":""},on:{"click":function($event){_vm.copyText(_vm.getContactTool(student).content)}}},[_vm._v(_vm._s(_vm.getContactTool(student).button))])],1)],1)])})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.progress')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.progress.toLowerCase()},[_vm._v(" "+_vm._s(_vm.$t(`courses.progress.${scope.row.progress}`))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"100","label":_vm.$t('column.firstLesson')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.isExistStudentClassTickets(scope.row.student_class_tickets) &&
              scope.row.student_class_tickets[0].first_lesson_log
          )?_c('router-link',{attrs:{"to":{
            name: 'privateLessonsTeachingLogDetail',
            params: {
              privateType: _vm.getPrivateType(scope.row.student_class_tickets[0]),
              courseId: scope.row.id,
              teachingLogId:
                scope.row.student_class_tickets[0].first_lesson_log
                  .teaching_log_id
            }
          }}},[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime( scope.row.student_class_tickets[0].first_lesson_log.started_at ))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"width":"100","label":_vm.$t('column.lastLesson')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.isExistStudentClassTickets(scope.row.student_class_tickets) &&
              scope.row.student_class_tickets[0].last_lesson_log
          )?_c('router-link',{attrs:{"to":{
            name: 'privateLessonsTeachingLogDetail',
            params: {
              privateType: _vm.getPrivateType(scope.row.student_class_tickets[0]),
              courseId: scope.row.id,
              teachingLogId:
                scope.row.student_class_tickets[0].last_lesson_log
                  .teaching_log_id
            }
          }}},[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime( scope.row.student_class_tickets[0].last_lesson_log.started_at ))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"width":"120","label":_vm.$t('column.nextLesson')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.isExistStudentClassTickets(scope.row.student_class_tickets) &&
              scope.row.student_class_tickets[0].next_lesson_log
          )?_c('router-link',{attrs:{"to":{
            name: 'privateLessonsTeachingLogDetail',
            params: {
              privateType: _vm.getPrivateType(scope.row.student_class_tickets[0]),
              courseId: scope.row.id,
              teachingLogId:
                scope.row.student_class_tickets[0].next_lesson_log
                  .teaching_log_id
            }
          }}},[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime( scope.row.student_class_tickets[0].next_lesson_log.started_at ))+" ")]):_c('span',[_vm._v("-")])]}}])}),(_vm.isEnded)?_c('el-table-column',{attrs:{"label":"結束原因"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('EndReasonViewer',{attrs:{"endReason":scope.row.end_reason || ''}})]}}],null,false,4265471783)}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":"Details","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: _vm.getCourseDetailLink(
                scope.row.student_class_tickets[0],
                scope.row.student_class_tickets.length
              ),
              params: {
                id: scope.row.id,
                classId: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-view"})])],1),(_vm.isEnded === 0 && (_vm.$store.getters['user/isRole']('super-admin') || _vm.isMyClass(scope.row.active_session_class_teachers)))?[_c('el-tooltip',{staticClass:"item",attrs:{"content":"Add a lesson log","placement":"top","popper-class":"tooltipColor"}},[(
                _vm.getPrivateType(scope.row.student_class_tickets[0]) != 'group'
              )?_c('router-link',{attrs:{"to":{
                name: 'privateClassesCreateLog',
                params: {
                  privateType: _vm.getPrivateType(
                    scope.row.student_class_tickets[0]
                  ),
                  id: scope.row.id
                }
              }}},[_c('i',{staticClass:"el-icon-plus"})]):_c('router-link',{attrs:{"to":{
                name: 'sessionClassesCreateLog',
                params: { id: scope.row.id }
              }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"content":"Add a progress report","placement":"top"}},[(
                _vm.getPrivateType(scope.row.student_class_tickets[0]) != 'group'
              )?_c('router-link',{attrs:{"to":{
                name: 'privateClassesCreateReport',
                params: {
                  privateType: _vm.getPrivateType(
                    scope.row.student_class_tickets[0]
                  ),
                  classId: scope.row.id
                }
              }}},[_c('i',{staticClass:"el-icon-document"})]):_c('router-link',{attrs:{"to":{
                name: 'sessionClassesCreateReport',
                params: { classId: scope.row.id }
              }}},[_c('i',{staticClass:"el-icon-document"})])],1),(_vm.isEnded === 0)?_c('el-tooltip',{key:"inactive",staticClass:"item",attrs:{"content":"End","placement":"top","popper-class":"tooltipColor"}},[_c('span',{on:{"click":function($event){return _vm.setEndLessonId(scope.row.id)}}},[_c('i',{staticClass:"far fa-stop-circle action-icon"})])]):_c('el-tooltip',{key:"active",staticClass:"item",attrs:{"content":"Start","placement":"top","popper-class":"tooltipColor"}},[_c('span',{on:{"click":function($event){return _vm.restartCustomLesson(scope.row.id)}}},[_c('i',{staticClass:"far fa-play-circle action-icon"})])])]:_vm._e(),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Edit","placement":"top","popper-class":"tooltipColor"}},[(
              _vm.getPrivateType(scope.row.student_class_tickets[0]) != 'group'
            )?_c('router-link',{attrs:{"to":{
              name: 'privateClassesEdit',
              params: {
                privateType: _vm.getPrivateType(
                  scope.row.student_class_tickets[0]
                ),
                class_id: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-edit-outline"})]):_c('router-link',{attrs:{"to":{ name: 'sessionClassesEdit', params: { id: scope.row.id } }}},[_c('i',{staticClass:"el-icon-edit-outline"})])],1):_vm._e(),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Delete","placement":"top","popper-class":"tooltipColor"}},[_c('i',{staticClass:"el-icon-delete action-icon warning-icon",on:{"click":function($event){return _vm.handleDelete({ id: scope.row.id, callback: _vm.loadPrivateLessons })}}})]):_vm._e()]}}])})],1),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.filterLessonsCondition.page,"total":_vm.privateLessonsListCount},on:{"current-change":_vm.changeLessonsPage}})],1),_c('EndLessonReasonDialog',{attrs:{"visible":Boolean(_vm.handleEndClassId),"classId":_vm.handleEndClassId},on:{"onCancel":_vm.clearEndClassId,"onSubmitted":() => {
        _vm.clearEndClassId();
        _vm.loadPrivateLessons();
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }